.Attendance-section{
    .info-head{
        color: #4C483E;
    }
    .form-chooser-wrp{
        padding-top: 0;
        & > div:last-child {
            padding-left: 0px;
        }
        & > div:first-child {
            padding-right: 0px;
        }
    }
    h1{
        color: #FF7940;
        font-weight: 700;
    }
    
    .button-sec{
        button{
            background: #FF3D00;
            display: flex;
            align-items: center;
            box-shadow: none;
            &:hover{
                background: #626262;            
            }
            &.pln-btn{
                border: 1px solid #FF3D00;
                background-color: transparent;
                color: #FF3D00;
            }
        }
    }
}


.Attendance-section-edit.provider-portal{
    background: transparent !important;
    min-height: auto;
    min-width: auto;
    .prof-basic{
        h4{
            font-weight: 700;
            margin: 0;
        }
        p{
            margin: 0;
        }
    }
    .Attendance-details{
        h3{
            font-weight: 700;
        }
        p{
            margin: 0;
        }
        ul{
            padding-bottom: 20px;
            position: relative;
            width: 100%;
            display: table;
           li{
            float: left;
            width: 50%;
           }
        }
        .note{
            font-size: 12px;
        }
    }

    .button-sec{
        .fill-btn{
            margin-top: 0 !important;
        }
    }
    // & + button{
    //     display: none !important;
    // }
}