.pdf-download{
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    .each-pdf-download{
        width: 110px;
        display: block;
        p{
            display: block;
            font-size: 12px;
        }
    }
}

.form-upload-sec{
    label{
        display: block;
        border: 1px dashed #FF3D00;
        text-align: center;
        padding: 40px;
        cursor: pointer;
    }
}
.form-sec-tab{
    .tab-header{
        .MuiTabs-flexContainer{
            button{
                &:nth-child(4){
                    position: absolute;
                    right: 150px;
                    top: 0;
                    @media only screen and (max-width: 991px) {
                        & {
                            position: relative;
                            right: unset;
                            top: unset;
                        }
                    }
                }
                &:last-child{
                    position: absolute;
                    right: 0;
                    top: 0;
                    @media only screen and (max-width: 991px) {
                        & {
                            position: relative;
                            right: unset;
                            top: unset;
                        }
                    }
                }
            }
            
        }
    }
    .tab-sec-wrp .tab-body {
        border-radius: 0px 0px 24px 24px;
    }
}

.providerFormBtn{
    @media only screen and (max-width: 600px) {
        &{
            position: fixed;
            z-index: 99;
            right: 15px;
            bottom: 20px;
            margin: 0 !important;
        }
        & + .dailyComms-sec-tab{
            margin-bottom: 70px;
        }
    }
}

.dailyComms-sec-tab{
    .tab-header{
        .MuiTabs-flexContainer{
            button{
                &:nth-child(4){
                    position: absolute;
                    right: 150px;
                    top: 0;
                    @media only screen and (max-width: 991px) {
                        & {
                            position: relative;
                            right: unset;
                            top: unset;
                        }
                    }
                }
                &:last-child{
                    position: absolute;
                    right: 0;
                    top: 0;
                    @media only screen and (max-width: 991px) {
                        & {
                            position: relative;
                            right: unset;
                            top: unset;
                        }
                    }
                }
            }
            
        }
    }
    .tab-sec-wrp .tab-body {
        border-radius: 0px 0px 24px 24px;
        @media only screen and (max-width: 600px) {
            & {
                & > .MuiTabPanel-root{
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
        }
    }
}
.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper{
    box-shadow: none;
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
// .MuiAutocomplete-popper *{
//     background: #1B160E !important;
//     box-shadow: none !important;
// }

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiAutocomplete-paper{
    &::-webkit-scrollbar {
        width: 0px;
        height: 0;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

[aria-labelledby="label-parentFormSelect"]{
    padding-top: 15px;
    padding-bottom: 15px;
}
[aria-labelledby="label-parentFormSelect"],
.parent-dropdown-options-list{
    background: #1B160E;
    box-shadow: none !important;
    min-width: 400px;
    @media only screen and (max-width: 500px) {
        &{
            min-width: auto;
        }
    }
    *{
        color: #FFF;
    }
    li{
        font-size: 14px;
        b{
            padding-bottom: 10px;
        }
        i{
            font-style: normal;
            font-size: 10px;
            padding-left: 15px;
        }
        hr{
            margin: 15px 0;
            border-color: #fff;
        }
        p{
            margin: 0;
        }
    }
}
[aria-labelledby="label-providerFormSelect"]{
    min-width: 400px;
    li{
        font-size: 14px;
        b{
            padding-bottom: 10px;
        }
        i{
            font-style: normal;
            font-size: 10px;
            padding-left: 15px;
        }
        p{
            margin: 0;
        }
    }
}
.provider-dropdown-options-list{
    li{
        font-size: 14px;
        b{
            padding-bottom: 10px;
        }
        i{
            font-style: normal;
            font-size: 10px;
            padding-left: 15px;
        }
        hr{
            margin: 15px 0;
        }
        p{
            margin: 0;
        }
    }
}
.MuiSelect-select{
    p{
        margin: 0;
        i{
            display: none;
        }
    }
}

.parents-rights-sec{
    ul{
        li{
            list-style: disc;
            list-style-position: inside;
        }
        &.number-list{
            li{
                list-style: decimal;
                list-style-position: inside;
                ul{
                    li{
                        list-style: disc;
                        list-style-position: inside;
                    }
                }
            }
        }
    }
}
.app-txt-padding0{
    .app-text-input-container{
        padding-top: 0 !important;
    }
}

.emergency-contact-table{
    tr{
        td{
            &:first-child{
                min-width: 140px !important;
            }
        }
    }
}
.maxWidth200{
    max-width: 200px;
    @media only screen and (max-width: 600px) {
        &{
            max-width: 100%;
            width: 100%;
        }
    }
}
.maxWidth200Con{
    @media only screen and (max-width: 600px) {
        &{
            .info-head.f-wrp{
                display: none;
            }
        }
    }
}
.textBoxZIndex{
    input{
        z-index: 99;
    }
}