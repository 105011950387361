.Attendance-section{
    .info-head{
        color: #4C483E;
    }
    .form-chooser-wrp{
        padding-top: 0;
        & > div:last-child {
            padding-left: 0px;
        }
        & > div:first-child {
            padding-right: 0px;
        }
    }
    h1{
        color: #FF7940;
        font-weight: 700;
    }
    
    .button-sec{
        button{
            background: #FF3D00 !important;
            display: flex;
            align-items: center;
            box-shadow: none;
            &:hover{
                background: #626262;            
            }
            &.pln-btn{
                border: 1px solid #FF3D00 !important;
                background-color: transparent !important;
                color: #FF3D00 !important;
            }
        }
    }
}


.Attendance-section-edit.parent-portal{
    background: transparent !important;
    min-height: auto;
    min-width: auto;
    .prof-basic{
        h4{
            font-weight: 700;
            margin: 0;
        }
        p{
            margin: 0;
        }
    }
    .Attendance-details{
        h3{
            font-weight: 700;
        }
        p{
            margin: 0;
            display: flex;
            align-items: center;
            gap: 15px;
            min-height: 65px;
            b{
                white-space: nowrap;
            }
            @media only screen and (max-width: 468px) {
                min-height: auto;
            }
            .MuiFormControl-root{
                margin-top: 0;
                .MuiInputBase-root.MuiOutlinedInput-root{
                    max-width: 145px;
                    @media only screen and (max-width: 468px) {
                        max-width: 100% !important;
                    }
                }
            }
        }
        ul{
            padding-bottom: 20px;
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            max-width: 400px;
            gap: 20px;
           li{
            width: 50%;
            @media only screen and (max-width: 468px) {
                width: 100% !important;
            }
           }
           li:nth-child(1){
            order: 1;
           }
           li:nth-child(2){
            order: 2;
            width: 45%;
           }
           li:nth-child(3){
            order: 3;
           }
           li:nth-child(4){
            order: 4;
            width: 45%;
           }
        }
        .note{
            font-size: 12px;
            min-height: auto;
        }
    }

    .button-sec{
        .fill-btn{
            margin-top: 0 !important;
            background: #FF3D00 !important;
            color: #fff !important;
        }
        .pln-btn-style{
            button:nth-child(1){
                color: #FF3D00 !important;
            }
        }
        @media only screen and (max-width: 767px) {
            .pln-btn-style{
                display: block;
                button:nth-child(1){
                    margin-bottom: 15px;
                    color: #FF3D00;
                }
            }
        }
    }
    // & + button{
    //     display: none !important;
    // }
}