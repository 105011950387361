.employeeTab-wrp {
    .red-btn {
        background-color: #FF3D00;
        color: #fff !important;
        margin-bottom: 0px;
        text-transform: none;

        @media only screen and (max-width: 991px) {
            margin-bottom: 20px;
        }

        @media only screen and (max-width: 600px) {
            & {
                position: fixed;
                z-index: 1;
                right: 15px;
                bottom: 20px;
                margin: 0;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .tab-sec-wrp {
            .tab-body {
                .MuiTabPanel-root {
                    padding: 0;
                }

                .profile-wrapper {
                    padding: 24px 10px 10px;
                }
            }
        }
    }

    // .MuiDataGrid-virtualScrollerRenderZone {
    //     width: 100%;
    // }
    // .MuiDataGrid-columnHeaders{
    //     display: none;
    // }
    // .MuiDataGrid-virtualScroller{
    //     overflow: auto;
    //     overflow-x: hidden;
    //     margin-top: 35px;
    //     padding-bottom: 25px;
    // }

    // .MuiDataGrid-row {
    //     display: grid;
    //     width: 100%;
    //     grid-template-areas:
    //         "a a c d"
    //         "b b c d";
    //     border-bottom: 1px solid #fff;
    //     justify-content: space-between;

    //     .MuiDataGrid-cell {
    //         border-bottom: none;
    //     }

    //     .MuiDataGrid-cell:nth-child(1) {
    //         order: 1;
    //         grid-area: a;
    //         min-height: 35px !important;
    //         max-height: 35px !important;
    //     }

    //     .MuiDataGrid-cell:nth-child(2) {
    //         order: 2;
    //         grid-area: b;
    //         min-height: 35px !important;
    //         max-height: 35px !important;
    //     }

    //     .MuiDataGrid-cell:nth-child(3) {
    //         order: 3;
    //         grid-area: c;
    //         min-height: 70px !important;
    //         max-height: 70px !important;
    //         max-width: 60px !important;
    //         min-width: 60px !important;
    //     }

    //     .MuiDataGrid-cell:nth-child(4) {
    //         order: 4;
    //         grid-area: d;
    //         min-height: 70px !important;
    //         max-height: 70px !important;
    //         max-width: 60px !important;
    //         min-width: 60px !important;
    //     }
    // }



}

.employeeTab-section {
    padding: 0;

    .bulletin-con-wrp {
        background: #fff;
        border-radius: 24px;
        padding: 24px;

        @media only screen and (max-width: 600px) {
            & {
                margin-bottom: 70px;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        & {
            margin-bottom: 70px;
        }
    }
}
