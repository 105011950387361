.standard-form {
    max-width: 550px;
    margin: 0 auto;

    .form-group {
        padding: 0;
    }

    .standard-btn {
        background-color: #FF7940;
        border-radius: 50px;
        color: #fff;
        cursor: pointer;

        &.Mui-disabled {
            cursor: not-allowed;
            background-color: rgba(145, 158, 171, 0.24);
            color: rgba(145, 158, 171, 0.8);
            pointer-events: all;
        }

        &.pln-btn {
            background-color: transparent;
            color: #B4AC9C;
            border: 1px solid #B4AC9C;
            box-shadow: none;
        }
    }

    &.form-full-width {
        max-width: 100%;
    }
}

.red-btn {
    display: block;
    height: 50px;
    padding: 0px 13px;
    border-radius: 8px;
    border: 1.2px solid #FF3D00;
    background: rgba(255, 255, 255, 0.05);
    color: #FF3D00 !important;
    text-align: center;
    width: 100%;
}

.card-btn {
    display: block;
    padding: 15px;
    text-align: left;
    border-radius: 4px;
    border: 1px solid #5D5848;
    background: rgba(255, 255, 255, 0.05);
    width: 100%;

    p {
        margin: 0;
        font-size: 13px;
        color: #B4AC9C;
    }
}

.pay-card-box {
    border: 1px solid #5D5848;
    border-radius: 8px;
    margin-left: 0px;
    padding: 24px;
    padding-left: 0;
    padding-top: 24px !important;
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
}



.form-group {
    position: relative;

    .custom-textfield {
        border-radius: 4px;

        .MuiOutlinedInput-notchedOutline {
            border-color: #B4AC9C;
        }

        label {
            font-size: 13px;
        }
    }
}

textarea {
    padding: 20px 12px;
    border-radius: 4px;
    border: 1px solid #B4AC9C;
    max-width: 100%;
    min-width: 100%;
}

.login-btn {
    max-width: 550px;
    margin: 0 auto;
    display: table;
    box-shadow: none;
}

.print-btn {
    background: #FF7940;
}

.print-btn:hover {
    background: #FF7940;
}

.google-auth-btn {
    max-width: 550px;
    margin: 0 auto;
    border-radius: 50px;
    border: 1px solid #B4AC9C;
    width: 100%;
    min-height: 45px;
    color: #050E10;
    font-size: 13px;

    svg {
        margin-right: 10px;
    }
}

.divider {
    margin-top: 10px;
    margin-bottom: 10px;
}

.MuiDivider-fullWidth {
    width: 100%;
    max-width: 100%;

    .MuiDivider-wrapper {
        padding-left: calc(15px * 1.2);
        padding-right: calc(15px * 1.2);
        height: 35px;
        display: flex;
        align-items: center;
    }
}

.auth-form {
    .MuiDivider-fullWidth {
        width: 100%;
        max-width: 550px;
    }
}

.MuiDivider-fullWidth.MuiDivider-root::after,
.MuiDivider-fullWidth.MuiDivider-root::before {
    border-color: #B4AC9C;
}

.img-upload-wrp {
    .each-img-blk {
        width: 125px;
        display: block;
        height: 125px;
        margin: 0 auto;
        border: 6px solid #FF7940;
        border-radius: 100%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 100%;
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
        }

        button {
            position: absolute;
            right: 0;
            top: 0;
            opacity: 0;
            transition: 0.5s all;
            background: #FF7940;
            border: none;
            padding: 1px;
            margin: 0;
            line-height: 0;
            border-radius: 5px;
            cursor: pointer;
            z-index: 9;

            svg {
                path {
                    fill: #211e1d;
                }
            }
        }

        &:hover {
            button {
                opacity: 1;
                transition: 0.5s all;
            }
        }
    }

    .upload-img {
        label {
            width: 125px;
            height: 125px;
            border: 6px solid #FF7940;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            background-color: #FFFFFF1A;

            svg {
                width: 50px;
                height: 50px;
            }
        }

        input#prop-img {
            opacity: 0;
            display: none;
        }
    }

    .sm-loader {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: #383534;
        display: flex !important;
        align-items: center;
        border-radius: 100%;
        width: 125px;
        height: 125px;

        svg {
            width: 85px !important;
            height: 85px !important;
            margin: 0 auto;

            path {
                fill: #FF7940;
            }
        }

    }
}

.child-profile {
    padding: 24px;
    border-radius: 20px;
    background-color: #211e1d;

    .MuiFormControl-root {
        width: 100%;
    }

    .custom-textfield {
        input {
            color: #B4AC9C !important;
        }
    }

    .app-text-input-container .MuiFormControl-root {
        background: #32302d;
        border: 1px solid #32302d;
        // height: 55px;
    }

    .MuiFormControl-root.MuiTextField-root {
        height: 45px;
        background: #32302d;

        label {
            font-size: 13px;
            color: #B4AC9C;
            // transform: translate(14px, 12px) scale(1);
        }

        input {
            padding: 5px 15px;
            height: 35px;
            color: #FFF4EE;
            font-size: 13px;
            background: #32302d !important;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: #32302d;
        }
    }

    .MuiFormControl-root.MuiFormControl-fullWidth.custom-textfield {
        label {
            font-size: 13px;
            // transform: translate(14px, 12px) scale(1);
            color: #B4AC9C;
        }

        #select-relation-select {
            padding: 12px 15px;
            color: #B4AC9C;
            font-size: 13px;
            background: #32302d !important;
        }

        input {
            font-size: 13px;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: #32302d;
        }
    }

    .app-text-input-container .MuiFormControl-root .MuiInputLabel-shrink {
        background-color: transparent;
        color: #B4AC9C !important;
        transform: translate(9px, -9px) scale(0.75);
    }
}


.add-child-btn {
    border-radius: 24px;
    border: 2px solid #B4AC9C;
    background: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
    align-items: center;
    cursor: pointer;

    @media only screen and (max-width: 767px) {
        & {
            min-height: 200px;
        }
    }

    svg {
        width: 85px;
        height: 85px;

        @media only screen and (max-width: 767px) {
            & {
                width: 50px;
                height: 50px;
            }
        }
    }

    span {
        display: block;
        color: #B4AC9C;
        margin-top: 20px;
        font-size: 18px;
    }
}



.button-sec.f-wrp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 35px;

    @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .pln-btn,
    .fill-btn,
    .cancel-btn {
        display: inline-block;
        height: 45px;
        padding: 0px 30px;
        border-radius: 50px;
        border: 2px solid #FF3D00;
        min-width: 140px;
        font-weight: 700;
        box-shadow: none;
    }

    .cancel-btn {
        border-color: transparent;
        color: #FF3D00;
    }

    .pln-btn {
        background: transparent;
    }

    .fill-btn {
        background-color: #FF3D00;
        color: #FFF4EE;
    }

    .fill-btn:hover {
        background-color: #FF3D00;
        color: #FFF4EE;
        cursor: pointer;
    }

    .MuiLoadingButton-loadingIndicator {
        width: 30px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
    }
}

.button-sec.f-wrp button:not(:last-child) {
    margin-right: 15px;
}

.info-head {

    h2,
    h3,
    span {
        margin: 0;
        color: #B4AC9C;

        i {
            font-style: normal;
            font-size: 12px;
        }
    }

    h2 {
        color: #FFF4EE;
    }
}

.padding-top-10px {
    padding-top: 10px;
}

.box-class-form {
    border: 1px solid #5D5848;
    border-radius: 7px;
    padding: 12px;

    label {
        font-size: 14px;
        color: #B4AC9C;
        font-weight: 700;
        padding-bottom: 13px;

    }

    .MuiFormGroup-root {

        label,
        div {
            padding-bottom: 0px;
            flex: 1;
            max-width: 250px;

            span {
                font-size: 12px;

                svg {
                    color: #FF7940;
                }
            }
        }
    }
}

.check-label {
    .MuiFormControlLabel-label {
        color: #FFF4EE;
        font-size: 12px;
    }

    svg {
        color: #FF7940 !important;

        path {
            color: #FF7940 !important;
        }
    }
}

.parent-portal {
    .general-info-sec {
        .MuiList-root {
            background: #1B1916;
            color: #FFF4EE;
        }

    }

    .stepper {
        .MuiStep-alternativeLabel {
            svg {
                fill: #626262;
            }

            .MuiStepConnector-line {
                border-color: #626262;
            }

            &.Mui-completed {
                svg {
                    fill: #FF7940;
                }

                .MuiStepLabel-label {
                    color: #FF7940;
                }

                .MuiStepConnector-line {
                    border-color: #FF7940;
                }
            }
        }

    }
}

.provider-portal {
    .general-info-sec {
        .MuiList-root {
            background: #1B1916;
            color: #FFF4EE;
        }

    }

    .stepper {
        .MuiStep-alternativeLabel {
            svg {
                fill: #626262;
            }

            .MuiStepConnector-line {
                border-color: #626262;
            }

            &.Mui-completed {
                svg {
                    fill: #FF7940;
                }

                .MuiStepLabel-label {
                    color: #FF7940;
                }

                .MuiStepConnector-line {
                    border-color: #FF7940;
                }
            }
        }

    }

    .C4KParentProviderAgreementSectionOne .box-class-form .inline-options label {
        max-width: max-content;
    }
}

.custom-add-element {
    position: relative;

    .MuiFormControl-root.MuiTextField-root {
        input {
            padding-right: 45px;
        }
    }

    button {
        position: absolute;
        right: 1.5px;
        top: 1px;
        border-radius: 5px;
        height: 100%;
        z-index: 9;
    }
}

.custom-delete-element {
    .each-custom-element {
        background: #32302d;
        position: relative;
        padding: 5px 15px;
        height: 47px;
        font-size: 13px;
        padding-right: 45px;
        display: flex;
        align-items: center;

        span {
            font-size: inherit;
            color: #FFF4EE;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: block;
        }

        button {
            position: absolute;
            right: 1.5px;
            top: 1px;
            border-radius: 5px;
            height: 100%;
            z-index: 9;
        }
    }

    .each-custom-element:not(:last-child) {
        margin-bottom: 24px;
    }
}

.panel-form-sec {
    padding-bottom: 35px;
}

.panel-form.calender-sec {
    >div {
        padding-top: 0;
    }
}

.form-chooser-wrp {
    display: flex;
    padding-top: 35px;
    @media only screen and (max-width: 991px) {
        padding-top: 0px;
    }

    &>div:first-child {
        padding-right: 20px;
    }

    &>div:last-child {
        padding-left: 20px;
    }

    .MuiFormControl-root {
        width: 100%;
        margin: 0;
    }

    @media only screen and (max-width: 600px) {
        & {
            flex-wrap: wrap;
        }

        &>div:first-child {
            padding-right: 0px;
        }

        &>div:last-child {
            padding-left: 0px;
        }
    }
}
.mob-display-none{
    @media only screen and (max-width: 900px) {
        display: none;
    }
}
.MuiFormControl-root.MuiTextField-root,
.MuiFormControl-root {
    .MuiOutlinedInput-notchedOutline {
        border-color: #5D5848;

        .provider-portal & {
            border-color: #B4AC9C;
        }
    }
}

.panel-form-sec.blk-bg {
    background: #0F0A01;

    * {
        color: #FFF4EE;
    }

    .info-head {
        h2 {
            font-weight: 700;
        }

        h3 {
            font-weight: 700;
            color: #B4AC9C;
        }
    }

    .MuiFormControl-root {
        width: 100%;
        margin-top: 15px;
        background: #1a150c;

        .MuiFormLabel-root {
            transform: translate(12px, 18px) scale(1);
            color: #B4AC9C !important;

            &.MuiInputLabel-shrink {
                transform: translate(12px, -8px) scale(0.75);
            }
        }
    }
    .payment-child-list {
        .MuiFormControl-root{
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
        }
    }

    .box-class-form {
        &>.MuiFormControl-root {
            background: transparent;
            margin-top: 0;

            &>label.MuiFormLabel-root {
                transform: translate(0px, 0px) scale(1);
            }
        }

        svg {
            fill: #FF7940;
        }
    }

    .MuiGrid-item {
        &>div {
            padding-top: 10px;
        }

        .MuiFormControl-root.MuiTextField-root.custom-textfield {
            padding-top: 0;
        }
    }

    .form-group {
        input {
            height: 45px !important;
            background: transparent !important;
            color: #FFF4EE !important;
        }
    }

    .button-sec {
        .ryt-btn-sec {
            display: flex;

            @media only screen and (max-width: 767px) {
                & {
                    display: block;
                }
            }
        }

        @media only screen and (max-width: 500px) {
            & {

                .lft-btn-sec,
                .ryt-btn-sec {
                    width: 100%;
                }
            }
        }

        .login-btn {
            border: 1px solid #FF3D00;
            background: transparent;
            border-radius: 50px;
            white-space: nowrap;
            padding: 0 45px;
            color: #FF3D00;
            box-shadow: none;
            max-width: max-content;
            min-width: max-content;
        }

        .fill-btn {
            padding: 0 45px;
            background-color: #FF3D00;
            color: #FFF4EE;
            border-color: #FF3D00;
            box-shadow: none;
            max-width: max-content;
            min-width: max-content;

            @media only screen and (max-width: 500px) {
                & {
                    max-width: 100%;
                    min-width: 100%;
                }
            }
        }

        @media only screen and (max-width: 767px) {
            & {
                button {
                    display: block;
                    margin-top: 20px;

                    &:not(:last-child) {
                        margin-right: 0;
                    }
                }
            }
        }

        @media only screen and (max-width: 500px) {
            & {
                button {
                    width: 100%;
                }
            }
        }
    }
}

.inline-options {
    flex-direction: initial;
    align-items: center;
    gap: 20px;

    .box-class-form & label {
        padding: 0;

        span {
            white-space: nowrap;

            @media only screen and (max-width: 767px) {
                & {
                    white-space: normal !important;
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        & {
            flex-direction: column;
            align-items: flex-start;
        }

        label,
        span {
            white-space: normal !important;
        }
    }
}

.yesNo-option{
    [role="radiogroup"]{
        flex-wrap: nowrap;
    }
}

.maxWidth_550 {
    max-width: 550px !important;
}

.minWidth_max {
    min-width: max-content !important;
}

.inline-box .MuiFormControl-root {
    padding: 0 !important;
    margin: 0 !important;
}

.provider-portal {
    .form-group {
        input {
            height: 45px !important;
        }
    }

    .MuiFormControl-root {
        width: 100%;
        margin-top: 15px;

        .MuiFormLabel-root {
            transform: translate(12px, 18px) scale(1);

            &.MuiInputLabel-shrink {
                transform: translate(12px, -8px) scale(0.75) !important;
            }
        }
    }

    .box-class-form {
        .MuiFormControl-root {
            margin-top: 0;

            .MuiFormLabel-root {
                transform: translate(0px, 0px) scale(1);
            }
        }

        .app-text-input-container {
            .MuiFormLabel-root {
                transform: translate(12px, 18px) scale(1);

                &.MuiInputLabel-shrink {
                    transform: translate(12px, -8px) scale(0.75) !important;
                }
            }
        }
    }
}

.tab-body {
    .panel-form-sec.blk-bg.forms-main-wrapper.f-wrp {
        padding: 0 !important;
        border-radius: 0 !important;
        padding-bottom: 24px !important;
    }
}

.provider-portal {
    .info-head * {
        font-weight: 700;
    }

    .info-head h2 {
        color: #0F0A01;
    }

    .info-head h3 {
        color: #5D5848;
    }

    .info-head span {
        color: #5D5848;
    }

    .box-class-form label {
        font-weight: 400;
    }
}


.provider-portal {
    .MuiDivider-fullWidth {
        background-color: #B4AC9C;
    }

    .check-label .MuiFormControlLabel-label {
        color: #0F0A01;
    }

    .button-sec.mob-btn-grid {
        .pln-btn {
            color: #FF3D00;

            &.Mui-disabled {
                color: #919eabcc;
                border-color: #919eabcc;
            }
        }
    }



}

[class$="-MuiStack-root"] {
    &>.MuiTextField-root {
        min-width: 100%;
    }
}

.MuiGrid-root.MuiGrid-item {
    &>[class^="css"] {
        padding-top: 0;
        overflow: visible;
    }
}

.border-option-input {
    border: 1px solid #B4AC9C;
    padding: 4px 8px 8px;
    border-radius: 8px;

    .MuiFormLabel-root {
        transform: none !important;
        font-size: 12px;
        padding-bottom: 5px;
    }

    .MuiFormGroup-root.MuiFormGroup-row {

        label,
        span {
            font-size: 13px;
            height: 20px;
        }
    }
}

.flex-field {
    display: flex;
    align-items: center;
    gap: 50px;

    p {
        margin: 0;
    }

    .MuiFormControl-root.MuiTextField-root.custom-textfield {
        margin-top: 0;
    }
}

.with-txtArea-sec {
    .with-txt-box {
        flex: 2 !important;
    }

    .MuiFormGroup-root label {
        min-width: 25%;
        max-width: max-content;
        margin: 0;

        @media only screen and (max-width: 991px) {
            & {
                min-width: max-content;
                max-width: max-content;
            }
        }
    }
}

label.main-head {
    color: #5D5848;
    font-weight: 700 !important;
}

.Weekly-schedule-table {
    p {
        color: #B4AC9C !important;
        font-size: 13px;
    }

    @media only screen and (max-width: 991px) {
        & {
            * {
                font-size: 13px;
            }

            tr {
                td {
                    .MuiFormControl-root {
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 991px) {
    .maxWidth300 {
        max-width: 300px;
    }
}

.inline-options.whiteSpace {
    label {
        max-width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .mob-title-fix {
        flex-direction: column;
        align-items: flex-start;
        white-space: normal !important;

        label {
            white-space: normal !important;
        }
    }

    .C4KRedetermination,
    .C4KAppSection {
        .inline-options {
            flex-direction: column;
            align-items: flex-start;
            white-space: normal !important;

            label {
                white-space: normal !important;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .button-sec.mob-btn-grid {
        .lft-btn-sec {
            display: none;
        }

        .ryt-btn-sec {
            display: flex !important;
            flex-wrap: wrap !important;
            justify-content: space-between;

            button {
                display: inline-block;
                margin-top: 20px;
                width: max-content;
                min-width: max-content !important;
            }
        }
    }

    .MuiStepper-root.MuiStepper-horizontal {
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }
    }

    .responsive-option {
        .inline-options {
            flex-direction: column;
            align-items: flex-start;

            label {
                span {
                    white-space: normal !important;
                }
            }
        }
    }
}