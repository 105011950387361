.provider-portal {
    background: #F5F0E7;
    min-height: 100vh;
    max-width: 100vw;

    .portal-main-structure {
        min-height: 100vh;
        max-width: 1920px;
        margin: 0 auto;

        .mob-drawer-btn {
            display: none;
        }

        .closeBackBtn {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: #0000008a;
            display: none;

            @media only screen and (max-width: 600px) {
                display: block;
            }
        }

        .app-portal-header {
            background: transparent;
            box-shadow: none;

            .MuiToolbar-root.MuiToolbar-regular {
                min-height: auto;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 24px;
            }

            .MuiTypography-root {
                color: #FF7940;
                font-family: 'Andika', sans-serif;
            }

            @media only screen and (min-width: 600px) {
                .mobile-menu-sec {
                    display: none;
                }
            }

            @media only screen and (max-width: 600px) {
                .mob-drawer-btn {
                    display: block;
                    min-height: auto;
                }

                .provider-drawer {
                    display: none;
                }

            }
        }

        .panel-main-content {
            .grid-sec-wrp {
                border-radius: 24px;
                background: #FFFFFF;
                padding: 0px 24px 24px 0px;
                width: 100%;
                margin: 0;
            }
        }

        @media only screen and (max-width: 991px) {
            .MuiDrawer-root.MuiDrawer-docked.provider-drawer {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 9;
                height: 100%;
            }
        }

        @media only screen and (max-width: 600px) {
            .MuiDrawer-root.MuiDrawer-docked.provider-drawer {
                z-index: 99;
            }
        }
    }

    .provider-drawer {
        &>.MuiDrawer-paper {
            background: #FFFFFF;
            justify-content: space-between;

            &::-webkit-scrollbar {
                width: 0px;
            }

            &::-webkit-scrollbar-track {
                background: #F5F0E7;
            }

            &::-webkit-scrollbar-thumb {
                background: #FF7940;
                border-radius: 50px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #FF7940;
            }

            .MuiDivider-fullWidth {
                width: calc(100% - 30px);
                margin: 0 auto;
                border-color: #FFF4EE;
            }
        }

        .logo-sec-wrp {
            min-height: 120px;
            transition: 0.5s all;

            .logo-img {
                display: block;
            }
        }

        ul {
            * {
                color: #B4AC9C;
                transition: 0.5s all;
                white-space: initial;
            }

            li {
                .listButton {
                    padding: 15px 20px;
                    position: relative;

                    .MobCloseBtn {
                        display: none;

                        @media only screen and (max-width: 991px) {
                            & {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                display: block;
                            }
                        }
                    }

                    .MuiListItemIcon-root {
                        span {
                            display: flex;

                            svg {
                                path {
                                    fill: #B4AC9C;
                                }

                                rect {
                                    fill: #FFFFFF4D;
                                }
                            }
                        }
                    }

                    &.logout {
                        span {
                            font-weight: 700;

                            svg {
                                path {
                                    fill: none;
                                    stroke: #B4AC9C;
                                }
                            }
                        }
                    }

                    &.parents {
                        span {
                            svg {
                                path {
                                    fill: none;
                                    stroke: #B4AC9C;
                                }
                            }
                        }
                    }

                    &:hover {
                        * {
                            color: #0F0A01;
                            transition: 0.5s all;
                        }

                        .MuiListItemIcon-root {
                            span {
                                svg {
                                    path {
                                        fill: #FF7940;
                                    }

                                    rect {
                                        fill: #FFFFFF4D;
                                    }
                                }
                            }
                        }

                        &.logout,
                        &.parents,
                        &.accountList {
                            span {
                                svg {
                                    path {
                                        fill: none;
                                        stroke: #FF7940;
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover,
                &.active {
                    * {
                        color: #0F0A01;
                        transition: 0.5s all;
                    }

                    .listButton {
                        background-color: #FFFFFF;
                    }

                    .MuiListItemIcon-root {
                        span {
                            svg {
                                path {
                                    fill: #FF7940;
                                }

                                rect {
                                    fill: #FFFFFF4D;
                                }
                            }
                        }
                    }

                    &.logout {
                        span {
                            svg {
                                path {
                                    fill: none;
                                    stroke: #FF7940;
                                }
                            }
                        }
                    }
                }

                &::after {
                    content: "";
                    width: 6px;
                    height: calc(100% - 10px);
                    position: absolute;
                    top: 5px;
                    right: 0;
                    background: #FF7940;
                    border-radius: 5px 0 0 5px;
                    opacity: 0;
                    transition: 0.5s all;
                }

                &:hover:after,
                &.active:after {
                    opacity: 1;
                    transition: 0.5s all;
                }
            }

        }

        &.MuiDrawer-docked {
            .logo-sec-wrp {
                min-height: 60px;
                transition: 0.5s all;
            }
        }

        @media only screen and (max-width: 900px) {
            & {
                &.drawer-close {
                    &+.closeBackBtn {
                        display: none;
                    }
                }

                &.drawer-open {
                    &+.closeBackBtn {
                        display: block;
                        z-index: 3;
                    }
                }
            }
        }
    }

    .panel-main-body {
        width: calc(100% - 240px);

        @media only screen and (min-width: 600px) and (max-width: 991px) {
            margin-left: 65px;
        }

        @media only screen and (max-width: 600px) {
            padding: 0 15px 24px;

            .panel-main-header {
                margin-bottom: 20px;
                position: sticky;
                top: 0;
                z-index: 9;

                .MuiToolbar-root.MuiToolbar-gutters {
                    padding: 15px 24px;
                    padding-left: 40px;
                    position: relative;
                    left: -15px;
                    background: #FFFFFF;
                    width: calc(100% + 30px);

                    .MuiTypography-root {
                        width: 100%;
                        text-align: center;
                    }

                    .left-sec {
                        position: absolute;
                        left: 0;
                        top: unset;
                    }
                }
            }
        }
    }

    .tab-sec-wrp {
        .tab-header {
            button {
                color: #B4AC9C;
                background: rgba(255, 255, 255, 0.50);

                &.Mui-selected {
                    color: #0F0A01;
                    background: #FFFFFF;
                }
            }

            .MuiTabs-indicator {
                background: #FFFFFF;
            }
        }

        .tab-body {
            background: #FFFFFF;

            @media only screen and (max-width: 600px) {
                &>.MuiTabPanel-root {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }

        @media only screen and (max-width: 991px) {
            & {
                padding: 5px;
                background: #fff;
            }

            .tab-header {
                .MuiTabs-scroller {
                    border-color: #98948A;
                    background-color: #FFFFFF;
                }

                button {
                    &.Mui-selected {
                        color: #FF7940;
                    }
                }

                .MuiTabs-indicator {
                    background-color: #FF7940;
                }
            }
        }
    }

    .provider-table {
        .MuiDataGrid-root.MuiDataGrid-withBorderColor {
            border-color: transparent;
        }

        .app-grid-tool-bar {
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: nowrap;

            .MuiFormControl-root {
                max-width: 250px;
            }
        }

        .MuiDataGrid-columnHeaders,
        .MuiDataGrid-footerContainer {
            border-color: transparent;
        }
    }


    .listButton {

        &.pricing,
        &.payments,
        &.inbox,
        &.administrators,
        &.dashboard {
            .MuiListItemIcon-root {
                span {
                    svg {
                        path {
                            fill: none !important;
                            stroke: #B4AC9C;
                        }

                        rect {
                            fill: #B4AC9C !important;
                        }
                    }
                }
            }

            &:hover {
                .MuiListItemIcon-root {
                    span {
                        svg {
                            path {
                                fill: none !important;
                                stroke: #FF7940 !important;
                            }

                            rect {
                                fill: #FFFFFF4D !important;
                            }
                        }
                    }
                }

            }
        }

    }

    .accident-sec-wrp {
        .standard-form.form-full-width {
            &>.info-head {
                display: none;

                &+.form-slider-box {
                    margin-top: 0;
                }
            }
        }
    }

    .app-table {
        .MuiDataGrid-virtualScroller {
            &::-webkit-scrollbar {
                height: 3px;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background: #B4AC9C;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #B4AC9C;
            }
        }

        [data-field="guardian"] {
            .MuiDataGrid-cellContent {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        // @media only screen and (max-width: 600px) {
        //     .MuiDataGrid-main,
        //     .MuiDataGrid-footerContainer{
        //         display: none;
        //     }
        // }

    }

    .mob-table-wrp {
        // display: none;

        // @media only screen and (max-width: 600px) {
        //     & {
        //         display: block;
        //         padding-top: 30px;
        //     }
        //     .responsiveTable td .tdBefore{
        //         left: 2px;
        //     }
        //     table {
        //         tr {
        //             border: none;
        //             border-bottom: 1px solid #98948A;

        //             td {
        //                 font-size: 14px;
        //             }
        //         }
        //     }
        // }
    }

    .pc-head,
    .mob-head {
        display: none;
    }

    @media only screen and (max-width: 600px) {
        & .mob-head {
            display: block;

            .panel-main-header {
                margin-bottom: 0;
            }

            .head-flex {
                display: flex;
                align-items: center;
                background: #fff;
                width: calc(100% + 30px);
                position: relative;
                left: -15px;
                padding-left: 10px;

                h4 {
                    font-weight: 400;
                    font-size: 18px;
                }
            }
        }
    }

    @media only screen and (min-width: 601px) {
        & .pc-head {
            display: block;
        }
    }
}




.provider-portal {
    &.light-bg {
        background: var(--lightBg_1);

        .provider-drawer>.MuiDrawer-paper {
            background: var(--lightBg_2);
        }

        .provider-drawer {
            ul {

                li:hover,
                li.active {
                    .listButton {
                        background-color: var(--lightBg_1);

                        &:hover {
                            * {
                                color: var(--lightHoverClr);
                            }
                        }
                    }
                }
            }
        }

        .parents-main-wrp {
            .table-sec-wrp {
                background: var(--lightBg_2);
            }
        }
    }

    //dark background
    &.dark-bg {
        background: var(--drkBg_1);

        * {
            color: #FFFFFF;
        }

        .provider-drawer>.MuiDrawer-paper {
            background: var(--drkBg_2);
        }

        .provider-drawer {
            ul {

                li:hover,
                li.active {
                    .listButton {
                        background-color: var(--drkBg_1);

                        &:hover {
                            * {
                                color: var(--drkHoverClr);
                            }
                        }
                    }
                }
            }
        }

        .parents-main-wrp {
            .table-sec-wrp {
                background: var(--drkBg_2) !important;
            }
        }

        .table-sec-wrp,
        .bulletin-con-wrp,
        .administrators-main-wrp .administrators-con-wrp,
        .inbox-main-wrp .inbox-con-wrp {
            background: var(--drkBg_2) !important;
        }


        .tab-sec-wrp {
            .tab-header {
                button.Mui-selected {
                    background: var(--drkBg_2);
                }

                .MuiTabs-indicator {
                    background: var(--drkBg_2);
                }
            }

            .tab-body {
                background: var(--drkBg_2);
            }
        }

        .info-head h2 {
            color: #FFFFFF;
        }

        .info-head h3 {
            color: #98948A;
        }

        .app-text-input-container {
            .MuiFormControl-root {
                .MuiInputBase-formControl {
                    input {
                        background: transparent;
                        color: #FFFFFF;
                    }

                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    textarea:-webkit-autofill,
                    textarea:-webkit-autofill:hover,
                    textarea:-webkit-autofill:focus,
                    select:-webkit-autofill,
                    select:-webkit-autofill:hover,
                    select:-webkit-autofill:focus {
                        -webkit-text-fill-color: #FFFFFF !important;
                        caret-color: #FFFFFF !important
                    }
                }

                // .MuiInputLabel-shrink{
                //     color: #fff !important;
                // }
            }
        }

        .accountTab-section {
            .profile-wrapper {
                background: transparent;

                .profile-details {
                    .custom-textfield {
                        input {
                            background: #32302d !important;
                            color: #fff !important;
                        }

                        .MuiInputLabel-shrink {
                            color: #fff !important;
                        }
                    }
                }

                @media only screen and (max-width: 991px) {
                    & {
                        flex-direction: column;
                        align-items: center;

                        .profile-details {
                            padding-left: 0;
                            padding-right: 0;
                            margin-left: 0;

                            .MuiGrid-root {
                                margin-left: 0;
                            }

                            .profile-wrapper {
                                padding-left: 0;

                                .MuiGrid-item {
                                    width: 100%;
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                }

                @media only screen and (max-width: 600px) {
                    & {
                        padding-left: 0;
                        padding-right: 0;

                        .profile-img-con .img-upload-wrp .upload-img label {
                            width: 155px;
                            height: 155px;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 991px) {
            & {
                .tab-sec-wrp {
                    background: var(--drkBg_2);

                    .tab-sec-wrp {
                        background: var(--drkBg_2);

                        .tab-header {
                            .MuiTabs-scroller {
                                background-color: var(--drkBg_2);
                            }
                        }
                    }
                }
            }
        }

        .providerForm-section .formsToCompleteWrp .box-class-form label,
        .providerForm-section .formsToCompleteWrp .box-class-form .Mui-focused {
            color: #FFFFFF;
        }

        .info-head span {
            color: #98948A !important;
        }

        .policy-wrp .info-head span {
            color: #98948A !important;
        }

        .policy-wrp .rdw-editor-main * {
            background-color: transparent !important;
        }
    }

    // text color BLUE

    &.blue-clr {
        .portal-main-structure {
            .app-portal-header {
                .MuiTypography-root {
                    color: var(--blueDark);
                }
            }
        }

        .header-notification {
            button {
                svg path {
                    color: var(--blueDark) !important;
                }
            }

            .language-box {
                svg {
                    path {
                        stroke: var(--blueDark) !important;
                    }
                }
            }
        }

        .provider-drawer {
            ul {
                li {
                    &::after {
                        background: var(--blueDark);
                    }
                }

                li:hover,
                li.active {
                    .listButton {
                        &:hover {
                            * {
                                color: var(--blueDark);
                            }

                            .MuiListItemIcon-root span svg path {
                                fill: var(--blueDark);
                            }

                            &.logout span svg path {
                                stroke: var(--blueDark);
                            }
                        }
                    }
                }

                li.active {
                    .listButton {
                        * {
                            color: var(--blueDark);
                        }

                        .MuiListItemIcon-root span svg path {
                            fill: var(--blueDark);
                        }

                        &.logout span svg path {
                            stroke: var(--blueDark);
                        }
                    }
                }

                .listButton {

                    &.logout,
                    &.pricing,
                    &.payments,
                    &.parents,
                    &.accountList,
                    &.attendance,
                    &.inbox,
                    &.administrators,
                    &.dashboard {
                        .MuiListItemIcon-root {
                            span {
                                svg {
                                    path {
                                        fill: none !important;
                                        stroke: #B4AC9C;
                                    }

                                    rect {
                                        fill: var(--blueDark) !important;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .MuiListItemIcon-root {
                                span {
                                    svg {
                                        path {
                                            fill: none !important;
                                            stroke: var(--blueDark) !important;
                                        }

                                        rect {
                                            fill: var(--blueDark) !important;
                                        }
                                    }
                                }
                            }

                        }
                    }

                }

                li.active {
                    .listButton {

                        &.logout,
                        &.pricing,
                        &.payments,
                        &.inbox,
                        &.attendance,
                        &.administrators,
                        &.dashboard {
                            .MuiListItemIcon-root {
                                span {
                                    svg {
                                        path {
                                            fill: none !important;
                                            stroke: var(--blueDark) !important;
                                        }

                                        rect {
                                            fill: var(--blueDark) !important;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

            }
        }

        .tab-sec-wrp {
            .tab-header {
                button.Mui-selected {
                    color: var(--blueLight);
                }

                .MuiTabs-indicator {
                    background-color: var(--blueLight);
                }
            }
        }

        .img-upload-wrp {
            .each-img-blk {
                button {
                    background: var(--blueLight);
                }
            }
        }

        .profile-wrapper {
            .profile-img-con {
                span {
                    border-color: var(--blueLight);
                }
            }
        }

        .button-sec.f-wrp .pln-btn,
        .button-sec.f-wrp .fill-btn,
        .button-sec.f-wrp .cancel-btn {
            border: 2px solid var(--blueLight);
        }

        .button-sec.f-wrp .cancel-btn {
            border-color: transparent;
            color: var(--blueLight);
        }

        .button-sec.mob-btn-grid {
            .pln-btn {
                color: var(--blueLight);
            }
        }

        .pln-btn-style .login-btn {
            border: 2px solid var(--blueLight);
            color: var(--blueLight);
        }

        .button-sec.f-wrp .fill-btn {
            background-color: var(--blueDark) !important;
        }

        .box-class-form .MuiFormGroup-root label span svg,
        .box-class-form .MuiFormGroup-root div span svg,
        .box-class-form .MuiFormGroup-root label span svg *,
        .box-class-form .MuiFormGroup-root div span svg * {
            color: var(--blueLight) !important;
        }

        .stepper {
            .MuiStep-alternativeLabel.Mui-completed {
                svg {
                    fill: var(--blueDark);
                }

                .MuiStepLabel-label {
                    color: var(--blueDark) !important;
                }

                .MuiStepConnector-line {
                    border-color: var(--blueDark);
                }
            }
        }

        .red-btn {
            border: 1.2px solid var(--blueDark);
            color: var(--blueDark) !important;

            &.providerFormBtn {
                background-color: var(--blueDark);
                color: var(--lightBg_2) !important;
            }
        }

        .bulletin-main-wrp .red-btn,
        .employeeTab-section .red-btn,
        .employeeTab-wrp .red-btn,
        .administrators-main-wrp .red-btn,
        .accountTab-section .red-btn {
            background-color: var(--blueDark);
            color: var(--lightBg_2) !important;
        }

        .red-txt p {
            color: var(--blueDark) !important;
        }

        .form-upload-sec {
            svg {
                path {
                    fill: var(--blueDark);
                }
            }
        }

        .pdf-download {
            .each-pdf-download {
                p {
                    color: var(--blueDark);
                }
            }
        }

        // .inbox-main-wrp .inbox-con-wrp .MuiDataGrid-row:nth-child(even) {
        //     background-color: var(--blueLight);
        // }

        .formScrollBtn button:hover {
            background: var(--blueDark);
            border-color: var(--blueDark);
        }

        .loader svg path {
            fill: var(--blueDark);
        }
    }

    // text color Green

    &.green-clr {
        .portal-main-structure {
            .app-portal-header {
                .MuiTypography-root {
                    color: var(--greenDark);
                }
            }
        }

        .header-notification {
            button {
                svg path {
                    color: var(--greenDark) !important;
                }
            }

            .language-box {
                svg {
                    path {
                        stroke: var(--greenDark) !important;
                    }
                }
            }
        }

        .provider-drawer {
            ul {
                li {
                    &::after {
                        background: var(--greenDark);
                    }
                }

                li:hover,
                li.active {
                    .listButton {
                        &:hover {
                            * {
                                color: var(--greenDark);
                            }

                            .MuiListItemIcon-root span svg path {
                                fill: var(--greenDark);
                            }

                            &.logout span svg path {
                                stroke: var(--greenDark);
                            }
                        }
                    }
                }

                li.active {
                    .listButton {
                        * {
                            color: var(--greenDark);
                        }

                        .MuiListItemIcon-root span svg path {
                            fill: var(--greenDark);
                        }

                        &.logout span svg path {
                            stroke: var(--greenDark);
                        }
                    }
                }

                .listButton {

                    &.logout,
                    &.pricing,
                    &.payments,
                    &.parents,
                    &.accountList,
                    &.attendance,
                    &.inbox,
                    &.administrators,
                    &.dashboard {
                        .MuiListItemIcon-root {
                            span {
                                svg {
                                    path {
                                        fill: none !important;
                                        stroke: #B4AC9C;
                                    }

                                    rect {
                                        fill: var(--greenDark) !important;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .MuiListItemIcon-root {
                                span {
                                    svg {
                                        path {
                                            fill: none !important;
                                            stroke: var(--greenDark) !important;
                                        }

                                        rect {
                                            fill: var(--greenDark) !important;
                                        }
                                    }
                                }
                            }

                        }
                    }

                }

                li.active {
                    .listButton {

                        &.logout,
                        &.pricing,
                        &.payments,
                        &.inbox,
                        &.attendance,
                        &.administrators,
                        &.dashboard {
                            .MuiListItemIcon-root {
                                span {
                                    svg {
                                        path {
                                            fill: none !important;
                                            stroke: var(--greenDark) !important;
                                        }

                                        rect {
                                            fill: var(--greenDark) !important;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

            }
        }

        .tab-sec-wrp {
            .tab-header {
                button.Mui-selected {
                    color: var(--greenLight);
                }

                .MuiTabs-indicator {
                    background-color: var(--greenLight);
                }
            }
        }

        .img-upload-wrp {
            .each-img-blk {
                button {
                    background: var(--greenLight);
                }
            }
        }

        .profile-wrapper {
            .profile-img-con {
                span {
                    border-color: var(--greenLight);
                }
            }
        }

        .button-sec.f-wrp .pln-btn,
        .button-sec.f-wrp .fill-btn,
        .button-sec.f-wrp .cancel-btn {
            border: 2px solid var(--greenLight);
        }

        .button-sec.f-wrp .cancel-btn {
            border-color: transparent;
            color: var(--greenLight);
        }

        .button-sec.mob-btn-grid {
            .pln-btn {
                color: var(--greenLight);
            }
        }

        .pln-btn-style .login-btn {
            border: 2px solid var(--greenLight);
            color: var(--greenLight);
        }

        .button-sec.f-wrp .fill-btn {
            background-color: var(--greenDark) !important;
        }

        .box-class-form .MuiFormGroup-root label span svg,
        .box-class-form .MuiFormGroup-root div span svg,
        .box-class-form .MuiFormGroup-root label span svg *,
        .box-class-form .MuiFormGroup-root div span svg * {
            color: var(--greenLight) !important;
        }

        .stepper {
            .MuiStep-alternativeLabel.Mui-completed {
                svg {
                    fill: var(--greenDark);
                }

                .MuiStepLabel-label {
                    color: var(--greenDark) !important;
                }

                .MuiStepConnector-line {
                    border-color: var(--greenDark);
                }
            }
        }

        .red-btn {
            border: 1.2px solid var(--greenDark);
            color: var(--greenDark) !important;

            &.providerFormBtn {
                background-color: var(--greenDark);
                color: var(--lightBg_2) !important;
            }
        }

        .bulletin-main-wrp .red-btn,
        .employeeTab-section .red-btn,
        .employeeTab-wrp .red-btn,
        .administrators-main-wrp .red-btn,
        .accountTab-section .red-btn {
            background-color: var(--greenDark);
            color: var(--lightBg_2) !important;
        }

        .red-txt p {
            color: var(--greenDark) !important;
        }

        .form-upload-sec {
            svg {
                path {
                    fill: var(--greenDark);
                }
            }
        }

        .pdf-download {
            .each-pdf-download {
                p {
                    color: var(--greenDark);
                }
            }
        }

        // .inbox-main-wrp .inbox-con-wrp .MuiDataGrid-row:nth-child(even) {
        //     background-color: var(--greenLight);
        // }

        .formScrollBtn button:hover {
            background: var(--greenDark);
            border-color: var(--greenDark);
        }

        .loader svg path {
            fill: var(--greenDark);
        }
    }

    // text color Orange

    &.orange-clr {
        .portal-main-structure {
            .app-portal-header {
                .MuiTypography-root {
                    color: var(--orangeDark);
                }
            }
        }

        .header-notification {
            button {
                svg path {
                    color: var(--orangeDark) !important;
                }
            }

            .language-box {
                svg {
                    path {
                        stroke: var(--orangeDark) !important;
                    }
                }
            }
        }

        .provider-drawer {
            ul {
                li {
                    &::after {
                        background: var(--orangeDark);
                    }
                }

                li:hover,
                li.active {
                    .listButton {
                        &:hover {
                            * {
                                color: var(--orangeDark);
                            }

                            .MuiListItemIcon-root span svg path {
                                fill: var(--orangeDark);
                            }

                            &.logout span svg path {
                                stroke: var(--orangeDark);
                            }
                        }
                    }
                }

                li.active {
                    .listButton {
                        * {
                            color: var(--orangeDark);
                        }

                        .MuiListItemIcon-root span svg path {
                            fill: var(--orangeDark);
                        }

                        &.logout span svg path {
                            stroke: var(--orangeDark);
                        }
                    }
                }

                .listButton {

                    &.logout,
                    &.pricing,
                    &.payments,
                    &.parents,
                    &.accountList,
                    &.attendance,
                    &.inbox,
                    &.administrators,
                    &.dashboard {
                        .MuiListItemIcon-root {
                            span {
                                svg {
                                    path {
                                        fill: none !important;
                                        stroke: #B4AC9C;
                                    }

                                    rect {
                                        fill: var(--orangeDark) !important;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .MuiListItemIcon-root {
                                span {
                                    svg {
                                        path {
                                            fill: none !important;
                                            stroke: var(--orangeDark) !important;
                                        }

                                        rect {
                                            fill: var(--orangeDark) !important;
                                        }
                                    }
                                }
                            }

                        }
                    }

                }

                li.active {
                    .listButton {

                        &.logout,
                        &.pricing,
                        &.payments,
                        &.inbox,
                        &.attendance,
                        &.administrators,
                        &.dashboard {
                            .MuiListItemIcon-root {
                                span {
                                    svg {
                                        path {
                                            fill: none !important;
                                            stroke: var(--orangeDark) !important;
                                        }

                                        rect {
                                            fill: var(--orangeDark) !important;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

            }
        }

        .tab-sec-wrp {
            .tab-header {
                button.Mui-selected {
                    color: var(--orangeLight);
                }

                .MuiTabs-indicator {
                    background-color: var(--orangeLight);
                }
            }
        }

        .img-upload-wrp {
            .each-img-blk {
                button {
                    background: var(--orangeLight);
                }
            }
        }

        .profile-wrapper {
            .profile-img-con {
                span {
                    border-color: var(--orangeLight);
                }
            }
        }

        .button-sec.f-wrp .pln-btn,
        .button-sec.f-wrp .fill-btn,
        .button-sec.f-wrp .cancel-btn {
            border: 2px solid var(--orangeLight);
        }

        .button-sec.f-wrp .cancel-btn {
            border-color: transparent;
            color: var(--orangeLight);
        }

        .button-sec.mob-btn-grid {
            .pln-btn {
                color: var(--orangeLight);
            }
        }

        .pln-btn-style .login-btn {
            border: 2px solid var(--orangeLight);
            color: var(--orangeLight);
        }

        .button-sec.f-wrp .fill-btn {
            background-color: var(--orangeDark) !important;
        }

        .box-class-form .MuiFormGroup-root label span svg,
        .box-class-form .MuiFormGroup-root div span svg,
        .box-class-form .MuiFormGroup-root label span svg *,
        .box-class-form .MuiFormGroup-root div span svg * {
            color: var(--orangeLight) !important;
        }

        .stepper {
            .MuiStep-alternativeLabel.Mui-completed {
                svg {
                    fill: var(--orangeDark);
                }

                .MuiStepLabel-label {
                    color: var(--orangeDark) !important;
                }

                .MuiStepConnector-line {
                    border-color: var(--orangeDark);
                }
            }
        }

        .red-btn {
            border: 1.2px solid var(--orangeDark);
            color: var(--orangeDark) !important;

            &.providerFormBtn {
                background-color: var(--orangeDark);
                color: var(--lightBg_2) !important;
            }
        }

        .bulletin-main-wrp .red-btn,
        .employeeTab-section .red-btn,
        .employeeTab-wrp .red-btn,
        .administrators-main-wrp .red-btn,
        .accountTab-section .red-btn {
            background-color: var(--orangeDark);
            color: var(--lightBg_2) !important;
        }

        .red-txt p {
            color: var(--orangeDark) !important;
        }

        .form-upload-sec {
            svg {
                path {
                    fill: var(--orangeDark);
                }
            }
        }

        .pdf-download {
            .each-pdf-download {
                p {
                    color: var(--orangeDark);
                }
            }
        }

        // .inbox-main-wrp .inbox-con-wrp .MuiDataGrid-row:nth-child(even) {
        //     background-color: var(--orangeLight);
        // }

        .formScrollBtn button:hover {
            background: var(--orangeDark);
            border-color: var(--orangeDark);
        }

        .loader svg path {
            fill: var(--orangeDark);
        }
    }

    // text color Yellow

    &.yellow-clr {
        .portal-main-structure {
            .app-portal-header {
                .MuiTypography-root {
                    color: var(--yellowDark);
                }
            }
        }

        .header-notification {
            button {
                svg path {
                    color: var(--yellowDark) !important;
                }
            }

            .language-box {
                svg {
                    path {
                        stroke: var(--yellowDark) !important;
                    }
                }
            }
        }

        .provider-drawer {
            ul {
                li {
                    &::after {
                        background: var(--yellowDark);
                    }
                }

                li:hover,
                li.active {
                    .listButton {
                        &:hover {
                            * {
                                color: var(--yellowDark);
                            }

                            .MuiListItemIcon-root span svg path {
                                fill: var(--yellowDark);
                            }

                            &.logout span svg path {
                                stroke: var(--yellowDark);
                            }
                        }
                    }
                }

                li.active {
                    .listButton {
                        * {
                            color: var(--yellowDark);
                        }

                        .MuiListItemIcon-root span svg path {
                            fill: var(--yellowDark);
                        }

                        &.logout span svg path {
                            stroke: var(--yellowDark);
                        }
                    }
                }

                .listButton {

                    &.logout,
                    &.pricing,
                    &.payments,
                    &.parents,
                    &.accountList,
                    &.attendance,
                    &.inbox,
                    &.administrators,
                    &.dashboard {
                        .MuiListItemIcon-root {
                            span {
                                svg {
                                    path {
                                        fill: none !important;
                                        stroke: #B4AC9C;
                                    }

                                    rect {
                                        fill: var(--yellowDark) !important;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .MuiListItemIcon-root {
                                span {
                                    svg {
                                        path {
                                            fill: none !important;
                                            stroke: var(--yellowDark) !important;
                                        }

                                        rect {
                                            fill: var(--yellowDark) !important;
                                        }
                                    }
                                }
                            }

                        }
                    }

                }

                li.active {
                    .listButton {

                        &.logout,
                        &.pricing,
                        &.payments,
                        &.inbox,
                        &.attendance,
                        &.administrators,
                        &.dashboard {
                            .MuiListItemIcon-root {
                                span {
                                    svg {
                                        path {
                                            fill: none !important;
                                            stroke: var(--yellowDark) !important;
                                        }

                                        rect {
                                            fill: var(--yellowDark) !important;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

            }
        }

        .tab-sec-wrp {
            .tab-header {
                button.Mui-selected {
                    color: var(--yellowLight);
                }

                .MuiTabs-indicator {
                    background-color: var(--yellowLight);
                }
            }
        }

        .img-upload-wrp {
            .each-img-blk {
                button {
                    background: var(--yellowLight);
                }
            }
        }

        .profile-wrapper {
            .profile-img-con {
                span {
                    border-color: var(--yellowLight);
                }
            }
        }

        .button-sec.f-wrp .pln-btn,
        .button-sec.f-wrp .fill-btn,
        .button-sec.f-wrp .cancel-btn {
            border: 2px solid var(--yellowLight);
        }

        .button-sec.f-wrp .cancel-btn {
            border-color: transparent;
            color: var(--yellowLight);
        }

        .button-sec.mob-btn-grid {
            .pln-btn {
                color: var(--yellowLight);
            }
        }

        .pln-btn-style .login-btn {
            border: 2px solid var(--yellowLight);
            color: var(--yellowLight);
        }

        .button-sec.f-wrp .fill-btn {
            background-color: var(--yellowDark) !important;
        }

        .box-class-form .MuiFormGroup-root label span svg,
        .box-class-form .MuiFormGroup-root div span svg,
        .box-class-form .MuiFormGroup-root label span svg *,
        .box-class-form .MuiFormGroup-root div span svg * {
            color: var(--yellowLight) !important;
        }

        .stepper {
            .MuiStep-alternativeLabel.Mui-completed {
                svg {
                    fill: var(--yellowDark);
                }

                .MuiStepLabel-label {
                    color: var(--yellowDark) !important;
                }

                .MuiStepConnector-line {
                    border-color: var(--yellowDark);
                }
            }
        }

        .red-btn {
            border: 1.2px solid var(--yellowDark);
            color: var(--yellowDark) !important;

            &.providerFormBtn {
                background-color: var(--yellowDark);
                color: var(--lightBg_2) !important;
            }
        }

        .bulletin-main-wrp .red-btn,
        .employeeTab-section .red-btn,
        .employeeTab-wrp .red-btn,
        .administrators-main-wrp .red-btn,
        .accountTab-section .red-btn {
            background-color: var(--yellowDark);
            color: var(--lightBg_2) !important;
        }

        .red-txt p {
            color: var(--yellowDark) !important;
        }

        .form-upload-sec {
            svg {
                path {
                    fill: var(--yellowDark);
                }
            }
        }

        .pdf-download {
            .each-pdf-download {
                p {
                    color: var(--yellowDark);
                }
            }
        }

        // .inbox-main-wrp .inbox-con-wrp .MuiDataGrid-row:nth-child(even) {
        //     background-color: var(--yellowLight);
        // }

        .formScrollBtn button:hover {
            background: var(--yellowDark);
            border-color: var(--yellowDark);
        }

        .loader svg path {
            fill: var(--yellowDark);
        }
    }
}


:root {
    // light-bg
    --lightBg_1: #F5F0E7;
    --lightBg_2: #FFFFFF;
    --lightHoverClr: #FF7940;
    // dark-bg
    --drkBg_1: #0F0A01;
    --drkBg_2: #211E1D;
    --drkHoverClr: #FF7940;
    // blue-bg
    --blueDark: #4075FF;
    --blueLight: #638EFF;
    // green-bg
    --greenDark: #56A101;
    --greenLight: #8FC652;
    // orange-bg
    --orangeDark: #FF3D00;
    --orangeLight: #FF7940;
    // yellow-bg
    --yellowDark: #F9A400;
    --yellowLight: #FFC107;
}