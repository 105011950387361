.providerForm-section {
    .tab-sec-wrp {
        .tab-header {
            button {

                // &:nth-child(3) {
                //     position: absolute;
                //     right: 150px;
                //     top: 0;
                // }
                &:last-child {

                    // position: absolute;
                    // right: 0;
                    // top: 0;
                    @media only screen and (max-width: 991px) {
                        & {
                            position: relative;
                            right: unset;
                            top: unset;
                        }
                    }
                }
            }
        }

        .tab-body {
            border-radius: 0px 0px 24px 24px;
        }
    }

    .formsToCompleteWrp {
        .box-class-form {

            label,
            .Mui-focused {
                color: #0F0A01;
                max-width: 100%;
            }
        }

        .app-text-input-container {
            .MuiFormControl-root {
                .MuiFormLabel-root {
                    transform: translate(12px, 18px) scale(1);

                    &.MuiInputLabel-shrink {
                        transform: translate(12px, -8px) scale(0.75);
                    }
                }
            }
        }
    }
}



.provider-portal {
    .pdf-download {
        display: flex;
        flex-wrap: wrap;
        gap: 35px;

        .each-pdf-download {
            width: 110px;
            display: block;

            p {
                color: #0F0A01;
                display: block;
                font-size: 12px;
            }
        }
    }
}


.form-upload-sec {
    label {
        display: block;
        border: 1px dashed #FF3D00;
        text-align: center;
        padding: 40px;
        cursor: pointer;
    }
}

.dailyCommUpload {
    .form-upload-sec {
        label {
            svg {
                width: 35px;
                height: 35px;

                path {
                    fill: #FF3D00;
                }
            }

            p {
                color: #FF3D00 !important;
            }
        }
    }
}

.c4k_parentProviderAgreement_sectionThree_form_Key_QN_8_2 {
    justify-content: center;
}

.weekly-schedule-box {
    max-width: 450px;
    margin: 0 auto;

    .Weekly-schedule-table {
        .MuiFormControl-root.MuiTextField-root {
            min-width: 150px;
        }
    }
}