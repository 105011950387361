.CPR-main-wrp {
    padding: 0;

    .table-sec-wrp {
        padding: 24px;
        background-color: #fff;
        border-radius: 24px;
        @media only screen and (max-width: 600px) {
            &.f-wrp{
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .MuiDataGrid-root {
            border-color: transparent;
        }

    }

    
}
