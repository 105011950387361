.letAnimate {
    .home-main-wrp {
        .animated-banner-wrp.f-wrp {
            width: 100%;
            background-image: url('../../assets/images/animatedBanner/Piringundines.png');
            background-size: 55% 70%;
            background-position: right top;
            background-repeat: no-repeat;
            display: block;

            img {
                display: block;
            }

            @media screen and (min-width: 1200px) {
                & {
                    min-height: 605px;
                    height: calc(100vh - 60px);
                }
            }

            @media screen and (max-width: 767px) {
                & {
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    background-size: contain;
                    background-position: right bottom;
                    // min-height: 575px;
                }
            }

            &::after {
                content: "";
                background: #60d02c;
                width: 100%;
                height: 30px;
                display: block;
                position: absolute;
                left: 0;
                bottom: -25px;
                z-index: 9;
                @media screen and (max-width: 767px) {
                    & {
                        height: 12%;
                    }
                }
            }

            .bannerBG-con {
                position: absolute;
                left: 0;
                top: 0;
                width: 40vw;
                z-index: 9991;
                background: transparent;

                @media screen and (max-width: 767px) {
                    & {
                        position: relative;
                        right: 0;
                        margin: 0 auto;
                        float: none;
                        max-width: 325px;
                        width: 100%;
                    }
                }

                .banner-content {
                    &>span {
                        position: relative;

                        .animatedK {
                            width: 25%;
                            position: absolute;
                            left: 5%;
                            top: 0;
                            animation: LogoKAnimation 3250ms linear alternate;
                            animation-delay: 0s;
                            @media screen and (max-width: 500px) {
                                & {
                                    left: 0;
                                    width: 33%;
                                }
                            }
                            @keyframes LogoKAnimation {

                                0% {
                                    transform: translate(calc(100vw - 125%), 0) rotate(-180deg) scale(0.5);
                                }

                                15% {
                                    transform: translate(calc(80vw - 125%), 0%) rotate(0deg);
                                }

                                70% {
                                    transform: translate(30vw, 100%) rotate(360deg);
                                }

                                100% {
                                    transform: translate(0, 0) rotate(0deg);
                                }
                            }
                        }

                        .animntlogo {
                            &+img {
                                padding-left: 25%;
                                animation: LogoMainAnimation 4700ms linear alternate;
                                animation-delay: 0s;

                                @keyframes LogoMainAnimation {

                                    0% {
                                        opacity: 0;
                                    }

                                    70% {
                                        opacity: 0;
                                    }

                                    100% {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .banner-main.f-wrp {
                height: 100%;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                overflow: hidden;

                // @media screen and (max-width: 767px) {
                //     & {
                //         display: none;
                //     }
                // }

                .bannerBG {
                    width: 100%;
                    z-index: 999;
                    position: relative;
                    display: block;
                    margin: 5% 0;
                    height: 100%;

                    &>img {
                        height: 100%;
                        width: 60%;
                        margin: 0 auto;
                        object-fit: contain;
                        object-position: center;
                        @media screen and (max-width: 767px) {
                            & {
                                display: none;
                            }
                        }
                    }
                }

                .cloud {
                    position: absolute;

                    &.cloud1 {
                        width: 95%;
                        bottom: 0;
                        right: 0%;
                        @media screen and (max-width: 767px) {
                            & {
                                display: none;
                            }
                        }
                    }

                    &.cloud2 {
                        right: 28%;
                        top: 4%;
                        width: 8.5%;
                        z-index: 99;

                        @media screen and (max-width: 767px) {
                            & {
                                display: none;
                            }
                        }
                    }

                    &.cloud3 {
                        right: 11%;
                        top: 0;
                        width: 18%;
                        z-index: 99;

                        @media screen and (max-width: 767px) {
                            & {
                                display: none;
                            }
                        }
                    }

                    &.cloud4 {
                        right: 4%;
                        bottom: 12%;
                        width: 60%;
                        z-index: 999;
                        height: 50%;
                        @media screen and (max-width: 767px) {
                            & {
                                bottom: 22%;
                            }
                        }
                        img {
                            height: 100%;
                            object-position: center;
                            object-fit: fill;
                        }
                    }
                }
            }

            .child-grup-sec {
                position: absolute;
                right: 0%;
                bottom: 20%;
                z-index: 9999;
                width: 37%;
                height: 55%;
                // overflow: hidden;
                @media screen and (max-width: 767px) {
                    & {
                        width: 60%;
                        height: 70%;
                    }
                }
                .child-grup {
                    position: absolute;

                    &.ChildDino {
                        width: 65%;
                        left: 0;
                        bottom: 10%;
                        transform: translate(0%, 8%);
                        animation: dinoAnimation 1s linear, dinoMoveAnimation 5s linear infinite;
                        animation-delay: 0s, 1.1s;
                        @media screen and (max-width: 767px) {
                            & {
                                bottom: -10%;
                            }
                        }
                        @keyframes dinoAnimation {
                            0% {
                                transform: translate(125%, -10%);

                            }

                            30% {
                                transform: translate(60%, -10%);

                            }

                            60% {
                                transform: translate(30%, 0%);

                            }

                            100% {
                                transform: translate(0%, 8%);
                            }
                        }

                        @keyframes dinoMoveAnimation {
                            0% {
                                transform: rotate(0deg);
                            }

                            50% {
                                transform: rotate(3deg);
                            }

                            100% {
                                transform: rotate(0deg);
                            }
                        }
                    }

                    &.Pony {
                        width: 47%;
                        right: 0;
                        bottom: 0;
                        animation: ponyAnimation 1s linear;

                        @keyframes ponyAnimation {
                            0% {
                                transform: translate(45%, 0%);
                            }

                            100% {
                                transform: translate(0%, 0%);
                            }
                        }
                    }

                    &.Ball {
                        width: 18%;
                        right: 50%;
                        bottom: 0;
                        animation: ballAnimation 1s linear, block_motionAnimation 5s linear infinite;
                        animation-delay: 0s, 1.1s;

                        @keyframes ballAnimation {
                            0% {
                                // transform: translate(100%, 0) rotate(360deg)
                                transform: rotate(360deg);
                                right: 0%;
                            }

                            100% {
                                // transform: translate(0%, 0) rotate(0deg);
                                transform: rotate(0deg);
                                right: 50%;
                            }
                        }
                    }

                    &.Block1 {
                        width: 20%;
                        right: 0;
                        bottom: 0;
                    }

                    &.Block2 {
                        width: 10%;
                        left: 0;
                        bottom: 0;
                        animation: block_2Animation 1s linear, block_motionAnimation 5s linear infinite;
                        animation-delay: 0s, 1.1s;

                        @keyframes block_2Animation {
                            0% {
                                // transform: translate(100%, 0) rotate(360deg)
                                transform: rotate(360deg);
                                left: 90%;
                            }

                            100% {
                                // transform: translate(0%, 0) rotate(0deg);
                                transform: rotate(0deg);
                                left: 0%;
                            }
                        }

                        @keyframes block_motionAnimation {
                            0% {
                                bottom: 0;
                            }

                            50% {
                                bottom: 10px;
                            }

                            100% {
                                bottom: 0px;
                            }
                        }
                    }

                    &.duck {
                        bottom: 0;
                        width: 20%;
                        left: 10%;
                        animation: duckAnimation 1s linear;

                        @keyframes duckAnimation {
                            0% {
                                transform: translate(125%, 0%);
                            }

                            100% {
                                transform: translate(0%, 0%);
                            }
                        }
                    }

                    &.Ballon1 {
                        width: 62%;
                        right: -25%;
                        top: 10%;
                    }

                    &.Ballon2 {
                        right: 0;
                        top: 0;
                        width: 80%;
                    }

                    &.Ballon3 {
                        right: -25%;
                        top: 33%;
                        width: 70%;
                    }
                }

                .balloon-grup {
                    height: 100%;
                    width: 50%;
                    right: 0;
                    overflow: hidden;
                    float: right;
                    animation: balloonAnimation 1700ms linear, block_motionAnimation 5s linear infinite;
                    animation-delay: 0s, 1.1s;

                    @keyframes balloonAnimation {
                        0% {
                            width: 0;
                        }

                        100% {
                            width: 50%;
                        }
                    }
                }
            }

            .flying-grup {
                width: 60%;
                display: block;
                margin: 0 auto;
                height: 90%;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;

                .flying {
                    position: absolute;

                    &.rocket {
                        width: 18%;
                        bottom: 25%;
                        left: -17%;
                        animation: rocketAnimation 1700ms linear, rocket_motionAnimation 5s linear infinite;
                        animation-delay: 0s, 2000ms;

                        @keyframes rocketAnimation {
                            0% {
                                transform: translate(100px, 0px) rotate(-65deg);
                            }

                            100% {
                                transform: translate(0px, 0px) rotate(0deg);
                            }
                        }

                        @keyframes rocket_motionAnimation {
                            0% {
                                transform: translate(0, 0px);
                            }

                            50% {
                                transform: translate(0, 15px);
                            }

                            100% {
                                transform: translate(0, 0px);
                            }
                        }
                        @media screen and (max-width: 767px) {
                            & {
                                width: 30%;
                            }
                        }
                    }

                    &.kite {
                        right: 25%;
                        top: 0;
                        width: 13%;
                        z-index: -1;
                        animation: kiteAnimation 1700ms linear, kite_motionAnimation 5s linear infinite;
                        animation-delay: 0s, 2000ms;

                        @keyframes kiteAnimation {
                            0% {
                                transform: translate(100%, 100%);
                            }

                            25% {
                                transform: translate(-30%, 75%);
                            }

                            50% {
                                transform: translate(30%, 50%);
                            }

                            75% {
                                transform: translate(-30%, 25%);
                            }

                            100% {
                                transform: translate(0%, 0%);
                            }
                        }

                        @keyframes kite_motionAnimation {
                            0% {
                                transform: translate(0, 0px);
                            }

                            50% {
                                transform: translate(10px, 0);
                            }

                            100% {
                                transform: translate(0, 0px);
                            }
                        }
                    }
                }
            }

            .banner-basic {
                height: 100%;
                top: 0;
                position: absolute;
                left: 0;
                bottom: 0;

                .world {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 66.5%;
                    z-index: 999;
                    height: 30.15%;
                    @media screen and (max-width: 767px) {
                        & {
                            width: 95.5%;                            
                        }
                    }

                    img {
                        height: 100%;
                    }
                }

                .bannerFooter {
                    position: absolute;
                    bottom: -2%;
                    left: 0;
                    width: 100%;
                }
            }



            .main-img-wrp {
                height: 100%;

                // .bannerBGmob{
                //     display: none;
                // }
                @media screen and (max-width: 767px) {
                    & {
                        // height: 50%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        height: 40vh;
                    }

                    .bannerBGmob {
                        display: none;
                        margin-top: 20%;
                    }
                }

                .bannerBG {
                    height: 100%;

                    img {
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }
            }

            .main-banner-wrp {
                background-size: contain;
                background-position: bottom right;
                background-repeat: no-repeat;

                @media screen and (max-width: 991px) {
                    & {
                        display: flex;
                        flex-direction: column-reverse;
                    }
                }

                @media screen and (max-width: 767px) {
                    & {
                        // height: 50%;
                        justify-content: end;
                        max-height: 100%;
                        height: calc(60vh - 200px);
                        width: 100%;
                        max-width: 100%;
                    }
                }

                .bannerBG-con.f-wrp {
                    position: absolute;
                    left: 0;
                    top: 0;

                    @media screen and (max-width: 991px) {
                        & {
                            position: relative;
                        }
                    }
                }

                .banner-content {
                    z-index: 99;
                    text-align: center;

                    @media screen and (max-width: 991px) {
                        & {
                            max-width: 400px;
                            margin: 0 auto;
                            float: none;
                        }
                    }
                    @media screen and (max-width: 500px) {
                        & {
                            max-width: 100%;
                        }
                    }

                    &>span {
                        width: 100%;
                        height: 100%;
                        display: block;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .banner-btn {
                        display: inline-block;
                        padding: 10px 20px;
                        color: #FFF9F5;
                        border-radius: 50px;
                        background: #FF7940;
                        width: 80%;
                        margin: 0 auto;
                        max-width: 225px;

                        @media screen and (max-width: 767px) {
                            & {
                                display: none;
                            }
                        }
                    }
                }

                span {
                    width: 100%;
                    display: block;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .bannerBG {
                    position: relative;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: right bottom;
                    }
                }
            }
        }

        .mob-btn-wrp {
            z-index: 9;
            text-align: center;
            padding-top: 5px;
            background: #60d02c;
            margin-top: 5px;
            display: none;

            button {
                display: inline-block;
                padding: 10px 20px;
                color: #FFF9F5;
                border-radius: 50px;
                background: #FF7940;
                width: 80%;
                margin: 0 auto;
                // max-width: 225px;
            }

            @media screen and (max-width: 767px) {
                & {
                    display: block;
                }
            }
        }
    }
    .delete-popup.MuiModal-root{
        z-index: 10000;
    }
}