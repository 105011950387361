.profile-wrapper {
    border-radius: 24px;
    padding: 24px;
    background: #0F0A01;
    display: flex !important;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }

    .profile-img-con {
        display: block;
        line-height: 0;
        max-width: 250px;
        @media only screen and (max-width: 767px) {
            margin: 0 auto;
            padding-bottom: 20px;
        }
        .img-upload-wrp {
            .each-img-blk {
                width: 100%;
                height: 100%;
                border: none;
            }

            .upload-img label {
                width: 250px;
                height: 250px;
                cursor: pointer;
            }
        }

        span {
            display: block;
            width: 250px;
            height: 250px;
            border-radius: 100%;
            border: 10px solid #FF7940;
            overflow: hidden;
            line-height: 0;

            @media only screen and (max-width: 991px) {
                & {
                    width: 200px;
                    height: 200px;
                }
            }

            img {
                height: 100%;
                object-fit: cover;
                object-position: center;
                border: 10px solid transparent;
            }
        }
    }

    .profile-details {
        padding: 0;
        border-radius: 0;
        background-color: transparent;
        padding-left: 25px;
        padding-bottom: 35px;
        @media only screen and (max-width: 767px) {
            padding-left: 0;
        }
        .profile-wrapper {
            padding: 35px 0 0px 35px;
            @media only screen and (max-width: 767px) {
                padding-left: 0;
            }
        }

        .custom-textfield {
            border: 1px solid #1b1916;
            background: #1b1916;
            margin-bottom: 20px;

            input {
                color: #FFF4EE;
            }

            .MuiInputLabel-shrink {
                background: transparent;
                color: #B4AC9C !important;
            }
        }
    }
}

.note-box.f-wrp {
    margin-top: 35px;
    padding: 20px;
    background: #0F0A01;
    border-radius: 15px;

    p {
        color: #FFF4EE;
        margin: 0;
        font-size: 12px;
        text-align: center;

        a {
            margin-left: 10px;
        }
    }
}

.provider-portal {
    .profile-wrapper {
        background: #ffffff;
    }

    .profile-details {
        .custom-textfield {
            border: none !important;
            background: #ffffff !important;
            margin-bottom: 20px;

            input {
                color: #0F0A01 !important;
                background-color: transparent !important;
            }

            .MuiInputLabel-shrink {
                background: transparent;
                color: #5D5848 !important;
            }

            .MuiOutlinedInput-notchedOutline span {
                padding-right: 0 !important;
            }
        }
        .pln-btn{
            color: #98948A;
            border-color: #98948A;
        }
    }

    .note-box.f-wrp {
        display: none;
        background: #FFFFFF;
    }

}