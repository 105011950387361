html,
body {
    background-color: #fff;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto !important;
    font-family: 'Roboto', sans-serif;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.f-wrp {
    width: 100%;
    display: block;
    position: relative;
    float: left;
}

img {
    width: 100%;
    display: block;
}

ul,
li {
    list-style: none;
    padding-left: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
}

.app-header {
    position: relative;
}


.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader svg {
    display: block;
    width: 100px;
    height: 100px;
    z-index: 99;
}

.loader svg path {
    fill: #FF7940;
}

.info-txt {
    margin-bottom: 20px;
}

.info-txt p {
    margin: 0;
}

.info-txt span {
    font-size: 12px;
}

.red-txt p {
    margin: 0;
    color: #FF7940 !important;
    font-size: 13px;
}

.red-box {
    background: #FF7940;
    border-radius: 4px;
    padding: 24px 24px;
    text-align: center;

}

.red-box p {
    color: #fff;
    max-width: 767px;
    margin: 0 auto;
}

.form-declaration p{
    margin: 0;
}
.form-declaration i{
    font-style: normal;
}
.formScrollBtn{
    margin: 0 auto;
    text-align: center;
    padding-top: 50px;
}
.formScrollBtn button{
    color: #B4AC9C;
    background: transparent;
    border: 1px solid #B4AC9C;
    padding: 15px;
    width: 50px;
    border-radius: 100%;
    cursor: pointer;
}
.formScrollBtn button:hover{
    background: #FF3D00;
    border-color: #FF3D00;
    color: #fff;
}
.formScrollBtn button:not(:last-child){
    margin-right: 30px;
}

.center-txt {
    display: block;
    text-align: center;
}


.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

























































































































@media only screen and (max-width: 800px) {}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 650px) {}

@media only screen and (max-width: 500px) {}

@media only screen and (max-width: 450px) {}

@media only screen and (min-width: 600px) {
    .custom-header {
        min-height: 45px;
    }
}