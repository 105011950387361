.auth-layout {
    max-width: 1980px;
    margin: 0 auto;

    .flex-box {
        display: flex;
        flex-wrap: wrap;

        .form-sec-wrp {
            width: 50%;
            padding-top: 10px;
            padding-bottom: 55px;

            @media only screen and (max-width: 767px) {
                & {
                    width: 100%;
                }
            }

            @media only screen and (min-width: 767px) and (max-width: 991px) {
                & {
                    padding-right: 20px;
                }
            }

            .auth-logo {
                display: block;
                width: 125px;

                @media only screen and (max-width: 767px) {
                    & {
                        margin: 0 auto;
                    }
                }
            }

            .auth-form {
                max-width: 375px;
                margin: 0 auto;

                .form-tile {
                    padding-top: 20px;
                    padding-bottom: 25px;

                    @media only screen and (max-width: 767px) {
                        & {
                            text-align: center;
                        }
                    }

                    h3 {
                        font-size: 24px;
                    }

                    h4 {
                        font-size: 16px;
                    }
                }

                .login-form {
                    .standard-btn {
                        margin-top: 20px;
                    }

                    .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
                        border-color: #B4AC9C;
                    }

                    .form-group .custom-textfield label {
                        color: #5D5848;
                    }
                }

                .additional-btn {
                    p {
                        margin: 0;
                        margin-top: 10px;
                        font-size: 14px;

                        a {
                            color: #FFAE00;
                            text-decoration: none;
                            white-space: nowrap;
                        }
                    }

                    @media only screen and (max-width: 767px) {
                        & {
                            text-align: center;
                        }
                    }
                }

                .password-requirement-points {
                    padding-top: 13px;

                    ul {
                        li {
                            float: left;
                            display: flex;
                            align-items: center;
                            width: 50%;
                            font-size: 12px;
                            margin-bottom: 5px;
                            color: #FF7940;
                            position: relative;

                            &::before {
                                content: '';
                                width: 20px;
                                height: 20px;
                                display: block;
                                background-color: rgba(255, 121, 64, 0.25);
                                margin-right: 5px;
                                border-radius: 100%;
                            }

                            &.checked::before {
                                background-image: url('../../assets/images/icons/tick.png');
                                background-size: 11px;
                                background-repeat: no-repeat;
                                background-position: center;
                            }
                        }
                    }
                }
            }
        }

        .form-img-wrp {
            width: 50%;
            min-height: 100vh;
            max-height: 1090px;

            @media only screen and (max-width: 767px) {
                & {
                    display: none;
                }
            }

            @media only screen and (min-width: 767px) and (max-width: 991px) {
                & {
                    padding-left: 20px;
                }
            }

            span {
                padding: 15px 0px 15px 0px;
                display: block;
                height: 100%;
                position: relative;

                img {
                    height: calc(100% - 30px);
                    object-fit: cover;
                    object-position: center;
                    position: absolute;
                    left: 0;
                    top: 15px;
                    border-radius: 25px;
                }
            }
        }
    }
    .app-text-input-container .MuiFormControl-root .MuiInputBase-formControl{
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            -webkit-text-fill-color: #6c6c6c !important;
            caret-color: #6c6c6c !important
        }
    }
    
}

.Signup-wrp {}