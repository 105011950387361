.dashboard-main-wrp {
    padding: 0px;
    padding-bottom: 24px;

    .each-board-items {
        background: var(--lightBg_2);
        padding: 14px;
        border-radius: 14px;

        .card-header {
            display: flex;
            align-items: center;

            span {
                padding: 10px;
                border-radius: 16px;
                background: linear-gradient(270deg, #FF7940 0%, #FF3D00 100%);
                width: 40px;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.logs {
                    svg path {
                        fill: var(--lightBg_2);
                    }
                }

                &.Forms {
                    svg path {
                        fill: var(--lightBg_2);
                    }
                }

                &.Accidents {
                    svg path {
                        fill: var(--lightBg_2);
                    }
                }
            }

            h4 {
                font-family: 'Andika', sans-serif;
                font-weight: 700;
                color: var(--drkBg_1);
                margin: 0;
                padding-left: 10px;
            }
        }

        .card-body {
            p {
                b {
                    display: block;
                    font-size: 22px;
                }
            }
        }

        &.activeChildBox {
            .card-header {
                span {
                    svg path {
                        fill: var(--lightBg_2);
                    }
                }
            }

            p {
                b {
                    font-size: 32px;
                }
            }
        }

        &.date-indicator-sec {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            h4 {
                margin: 0;
                width: 100%;
            }

            span {
                border-left: 1.5px solid #4C483E;
                padding-left: 20px;
                margin-left: 10px;
            }
        }

        &.attendance-box-wrp {
            .card-header {
                span {
                    svg path {
                        fill: var(--lightBg_2);
                    }
                }
            }

            .attendanceList-sec {
                padding-top: 25px;

                ul {
                    li {
                        display: flex;
                        align-items: center;

                        span {
                            padding-right: 10px;
                        }

                        p {
                            margin: 0;
                            font-size: 13px;
                            white-space: nowrap;

                        }

                        h4 {
                            margin: 0;
                            width: 100%;
                            font-size: 14px;
                        }

                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    .inbox-card {
        background-image: url('../../../assets/images/inboxCardBG.png');
        height: 100%;
        background-size: 100% 100%;
        background-position: center;
        width: 120px;
        margin: 0 auto;
        float: none;
        text-align: center;
        padding: 20px;
        color: #fff;

        span {
            width: 55px;
            height: 55px;
            border-radius: 16px;
            border: 1px solid #FFF;
            background: rgba(255, 255, 255, 0.25);
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;

            svg path {
                stroke: var(--lightBg_2);
            }
        }

        h4 {
            margin-bottom: 0;
            margin-top: 5px;

            b {
                display: block;
                font-size: 36px;
                font-weight: 700;
                line-height: 1;
            }
        }

        p {
            font-size: 13px;
        }
    }

    .pln-btn {
        color: var(--orangeDark);
        font-size: 13px;
        font-weight: normal;
        margin-top: 15px;

        svg {
            width: 17px;
            height: 17px;
        }
    }

    .graph-wrp-sec {
        display: flex;

        .graph-img {
            width: 100px;
            display: block;
            position: relative;

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 16px;
            }
        }

        .graph-box-card.f-wrp {
            border-radius: 16px;
            background: linear-gradient(270deg, #FF7940 0.11%, #FF3D00 99.85%);
            width: calc(100% - 100px);
            padding: 20px;
            height: 320px;

            .card-header {
                display: flex;
                align-items: center;

                span {
                    width: 55px;
                    height: 55px;
                    border-radius: 16px;
                    border: 1px solid #FFF;
                    background: rgba(255, 255, 255, 0.25);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg path {
                        stroke: var(--lightBg_2);
                    }
                }

                h4 {
                    font-family: "Andika", sans-serif;
                    font-weight: 700;
                    color: #FFF;
                    margin: 0;
                    padding-left: 10px;
                }
            }

            .price-sec-container {
                display: flex;
                justify-content: space-between;
                margin-top: 15px;
                .each-price-sec {

                    h4,
                    p,
                    h2 {
                        margin: 0;
                        color: #FFF;
                    }

                    p {
                        font-size: 12px;
                    }

                    h2 {
                        font-weight: 700;
                    }
                }
            }
        }
    }

}