.clock-in-daily{
    .info-head{
        color: #4C483E;
    }
    h1{
        color: #FF7940;
        font-weight: 700;
    }
    button{
        background: #FF3D00;
        display: flex;
        align-items: center;
        &:hover{
            background: #626262;            
        }
    }
}