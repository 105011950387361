.notificationBell{
    .notification-header{
        display: flex;
        justify-content: space-between;
        padding: 5px 15px 5px 15px;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        height: 55px;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 0;
            width: calc(100% - 30px);
            height: 1px;
            background-color: #f1f1f1;
        }

        button{
            display: none;
        }
    }
    .notification-list{
        background: #0F0A01;
        padding: 10px;
        height: calc(100vh - 100px) !important;
        overflow: hidden;
        padding-top: 10px;
        padding-bottom: 20px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
        li{
            background: #211e1d;
            padding: 10px;
            border-radius: 20px;
            &:not(:last-child){
                margin-bottom: 10px;
            }
            span{
                font-size: 15px;
            }
            p{
                font-size: 12px;
            }
            *{
                color: #ffffff;
            }
        }
    }
    @media only screen and (min-width: 600px) {
        // .MuiPaper-root.MuiPaper-elevation,
        .notification-list{
            min-width: 375px !important;
            max-width: 375px !important;
            width: 100% !important;
        }
    }
    @media only screen and (max-width: 599px) {
        // .MuiPaper-root.MuiPaper-elevation,
        .notification-list{
            min-width: 100% !important;
            max-width: 100% !important;
            width: 100% !important;
        }
    }
}
.header-notification{    
    button{
        svg{
            path{
                color: #FF7940;
            }
        }
    }
}
.parentNotificationBell{
    .notification-list{
        background: #0F0A01;
        li{
            background: #211e1d;
            *{
                color: #ffffff;
            }
        }
    }
    // .MuiPopover-paper.MuiPaper-elevation{
    //     top: 0 !important;
    // }
}

.providerNotificationBell{
    .notification-list{
        background: #919eab14;
        li{
            background: #ffffff;
            *{
                color: #0F0A01;
            }
        }
    }
    // .MuiPopover-paper.MuiPaper-elevation{
    //     top: 0 !important;
    // }
}

.notification-wrapper{
    height: calc(100vh - 35px);
    overflow: hidden;
}